<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import { Breakpoints } from '~/config';

interface Props {
  filtersAmount?: number;
}

withDefaults(defineProps<Props>(), {
  filtersAmount: 4
});

const { width } = useWindowSize();
</script>
<template>
  <section class="mt-8 min-h-[30rem] w-full">
    <!-- Input filters -->
    <div class="grid gap-6 lg:grid-cols-5">
      <div
        v-for="i in width >= Breakpoints.lg ? filtersAmount : 1"
        :key="i"
        class="space-y-2"
      >
        <USkeleton class="hidden h-3.5 w-20 lg:block" />
        <USkeleton class="h-10 w-full" />
      </div>
    </div>

    <!-- Table -->
    <div
      class="mt-6 rounded-md"
      :class="{
        'u-border-gray-200 border p-4': width >= Breakpoints.lg,
      }"
    >
      <div class="grid grid-cols-1 gap-y-4">
        <div
          v-for="i in width >= Breakpoints.lg ? 10 : 1"
          :key="i"
        >
          <div class="grid gap-4 lg:grid-cols-5">
            <USkeleton
              v-for="x in 5"
              :key="x"
              class="h-36 w-full lg:h-7"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <div
      class="u-border-gray-200 ml-auto grid w-full gap-x-2 border-t pt-6 lg:w-56 lg:grid-cols-6"
    >
      <USkeleton
        v-for="i in width >= Breakpoints.lg ? 6 : 1"
        :key="i"
        class="h-10 w-full lg:h-6"
      />
    </div>
  </section>
</template>
